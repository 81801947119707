import React from 'react';
import { Root } from './style';

import PageHeader from 'components/Common/PageHeader';
import HelpUs from 'components/Common/HelpUs';
import SiteSection from 'components/Common/SiteSection';

import blueWhale from 'assets/HODLRanks/Rank/blue-whale.svg';
import crab from 'assets/HODLRanks/Rank/crab.svg';
import dolphin from 'assets/HODLRanks/Rank/dolphin.svg';
import humpbackWhale from 'assets/HODLRanks/Rank/humpback-whale.svg';
import killerWhale from 'assets/HODLRanks/Rank/killer-whale.svg';
import kraken from 'assets/HODLRanks/Rank/kraken.svg';
import megalodon from 'assets/HODLRanks/Rank/megalodon.svg';
import octopus from 'assets/HODLRanks/Rank/octopus.svg';
import shark from 'assets/HODLRanks/Rank/shark.svg';
import shrimp from 'assets/HODLRanks/Rank/shrimp.svg';
import swordfish from 'assets/HODLRanks/Rank/swordfish.svg';
import tunaFish from 'assets/HODLRanks/Rank/tuna-fish.svg';

import Rank from './Rank';

const HODLRanks = () => {
  return (
    <Root className="body-width">
      <PageHeader page="hodlRanks"/>
      <div className="ranks">
        <Rank name="Megalodon" image={megalodon} threshold=">20T"/>
        <Rank name="Kraken" image={kraken} threshold=">15T"/>
        <Rank name="Blue Whale" image={blueWhale} threshold=">10T"/>
        <Rank name="Humpback Whale" image={humpbackWhale} threshold=">5T"/>
        <Rank name="Killer Whale" image={killerWhale} threshold=">1T"/>
        <Rank name="Shark" image={shark} threshold=">500B"/>
        <Rank name="Dolphin" image={dolphin} threshold=">250B"/>
        <Rank name="Swordfish" image={swordfish} threshold=">150B"/>
        <Rank name="Tuna Fish" image={tunaFish} threshold=">50B"/>
        <Rank name="Octopus" image={octopus} threshold=">25B"/>
        <Rank name="Crab" image={crab} threshold=">5B"/>
        <Rank name="Shrimp" image={shrimp} threshold="<5B"/>
      </div>
      <HelpUs/>
      <SiteSection path="hodlRanks.contentSection" image="contentImage"/>
    </Root>
  );
};

export default HODLRanks;
